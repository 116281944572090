export enum GlossaryFamilyCodes {
  leasedLine = 'leased_line',
  microwave = 'microwave',
  ongoing = 'ongoing',
  fttn = 'fttn',
  ftts = 'ftts',
}

export enum GlossaryRadioStatusCodes {
  dismantled = 'dismantled',
  live = 'live',
}

export enum GlossaryStatusCodes {
  up = 'up',
}

export enum GlossaryBmStatusCodes {
  keep = 'keep',
}
